// Set a value in session storage
export const setStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

// Get a value from session storage
export const getStorage = (key) => {
  const value = sessionStorage.getItem(key);

  return value;
};

export const checkPageState = (page) => {
  const surveyId = getStorage("surveyId");

  if (!surveyId) {
    return false;
  }

  const pageState = getStorage(`${surveyId}-state`);

  const state = pageState ?JSON.parse( pageState) : {};

  return state[page] ? true : false;
};

export const setPageState = (page) => {
  const surveyId = getStorage("surveyId");

  if (!surveyId) {
    return false;
  }

  const pageState = getStorage(`${surveyId}-state`);

  const state = pageState ? JSON.parse( pageState) : {};

  state[page] = true;

  setStorage(`${surveyId}-state`, JSON.stringify(state));
};
             