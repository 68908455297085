import { createElement } from "react";
import { SelectDatepicker } from "react-select-datepicker";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from "survey-react-ui";
import { VCSurveyClientMinAge } from "../../config/config";
import "../../styles/datePicker.css";
import { formatDate, getLocaleDate } from "../../utils/dateFormat";
import { subtractDate } from "../../utils/dateOffset";

// A model for the new question type
export class QuestionDatePickerModel extends Question {
  getType() {
    return "date-picker-dob";
  }
  setValue(value) {
    this.value = value;
  }
}

// Register `QuestionDatePickerModel` as a model for the `date-picker-dob` type
export function registerDatePickerdob() {
  ElementFactory.Instance.registerElement("date-picker-dob", (name) => {
    return new QuestionDatePickerModel(name);
  });
}
// Add question type metadata for further serialization into JSON
Serializer.addClass(
  "date-picker-dob",
  [],
  function () {
    return new QuestionDatePickerModel("");
  },
  "question"
);
// A class that renders questions of the new type in the UI
export class SurveyQuestionDatePicker extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    // Initialize the component's state
    this.state = this.question.value
      ? getLocaleDate(this.question.value)
      : null;
  }

  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  handleDateChange = (value) => {
    if (value?.getMonth && value?.getFullYear && value?.getDate) {
      this.question.value = formatDate(value);
    }
  };

  renderElement() {
    return (
      <SelectDatepicker
        selectedDate={this.state}
        onDateChange={this.handleDateChange}
        maxDate={subtractDate(VCSurveyClientMinAge * 365)}
        labels={{
          dayPlaceholder: "Day",
          monthPlaceholder: "Month",
          yearPlaceholder: "Year",
        }}
      />
    );
  }
}
// Register `SurveyQuestionDatePicker` as a class that renders `date-picker-dob` questions
ReactQuestionFactory.Instance.registerQuestion("date-picker-dob", (props) => {
  return createElement(SurveyQuestionDatePicker, props);
});
