import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSurveyAPI } from "../api/survey";
import LoadingIndicator from "../components/loadingIndicator";
import styles from "../styles/start.module.css";
import { checkPageState, setPageState, setStorage } from "../utils/storage";

const Start = () => {
  const navigation = useNavigate();
  const result = window.location.href.split("?");
  const result_string = result[1].split("&");
  const surveyId = result_string[0].split("survey_id=")[1];
  const patientId = result_string[1].split("patient_id=")[1];

  const load = async () => {
    const data = await getSurveyAPI(surveyId, patientId);
    data.name && setStorage("displayName", data.name);
    setStorage("surveyActive", data.isSurveyActive);
    setStorage("surveySubmitMessage", data.message);
    setStorage("study_synopsis", data.study_synopsis);
    data.study_name && setStorage("studyName", data.study_name);

    if (data?.isSurveyActive) {
      navigation("/terms", { name: data.name });
    } else {
      setPageState("terms");
      setPageState("dobPage");
      setPageState("survey");
      setPageState("thankyou");
      navigation("/blank");
    }
  };

  useEffect(() => {
    setStorage("surveyId", surveyId);
    setStorage("userKeyValue", patientId);

    const finished = checkPageState("survey");
    if (finished) {
      navigation("/thankyou");
    } else load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <div className={styles.body}>
      <LoadingIndicator />
      <p className={styles.text}>Please wait!</p>
    </div>
  );
};

export default Start;
