export const formatDate = (date) => {
  const day = date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate();
  const month =
    date?.getMonth() + 1 < 10
      ? `0${date?.getMonth() + 1}`
      : date?.getMonth() + 1;

  return `${date?.getFullYear()}-${month}-${day}`;
};

export const vaccineDateFormat = (date) => {
  return `${date?.getMonth() + 1}-${date?.getDate()}-${date?.getFullYear()}`;
};

// converts date_of_birth in survey data to MM-DD-YYYY format
export const formatDateDob = (survey) => {
  const dob = survey?.date_of_birth;

  if (!dob) return survey;

  const date = getLocaleDate(dob);

  const day = date?.getDate() < 10 ? `0${date?.getDate()}` : date?.getDate();
  const month =
    date?.getMonth() + 1 < 10
      ? `0${date?.getMonth() + 1}`
      : date?.getMonth() + 1;

  survey.date_of_birth = `${month}-${day}-${date?.getFullYear()}`;

  return survey;
};

export const getLocaleDate = (date) => {
  // parse the date of the format YYYY-MM-DD
  date = date.split("-");
  const dateObj = new Date();
  dateObj.setFullYear(date[0]);
  dateObj.setMonth(date[1] - 1);
  dateObj.setDate(date[2]);
  dateObj.setHours(0, 0, 0, 0);
  return dateObj;
};
