import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSurveyContent } from "../api/survey";
import Modal from "../components/modal";
import LoadingModal from "../components/modal/loadingModal";
import {
  getStorage,
  checkPageState,
  setPageState,
  setStorage,
} from "../utils/storage";
import styles from "../styles/terms.module.css";
import HtmlParser from "react-html-parser";
import { useTracking } from "@velocity/tracking";

const Terms = () => {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);

  useTracking({
    user_id: getStorage("userKeyValue"),
    custom_properties: {
      survey_id: getStorage("surveyId"),
      patient_id: getStorage("userKeyValue"),
    },
  });

  useEffect(() => {
    const terms = checkPageState("terms");
    if (terms) {
      let result = JSON.parse(getStorage("APIResult"));

      navigation("/survey", {
        state: {
          resultJSON: result,
          surveyContent: result?.survey_json,
          is_callback_requested: result?.is_callback_requested,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (checked) {
        setLoading(true);
        const result = await getSurveyContent({
          surveyId: getStorage("surveyId"),
          patientId: getStorage("userKeyValue"),
        });
        setStorage("APIResult", JSON.stringify(result));

        result?.is_callback_requested &&
          setStorage("isCallbackRequested", result?.is_callback_requested);
        setPageState("terms");
        navigation("/survey", {
          state: {
            resultJSON: result,
            surveyContent: result?.survey_json,
          },
        });
    } 
    else {
      setError(true);
    }
  };

  const handleChange = (event) => {
    event.target.checked && setError(false);
    setChecked(event.target.checked);
    setStorage("termsChecked", event.target.checked);
  };

  return (
    <div>
      <Modal isOpen={loading}>
        <LoadingModal />
      </Modal>
      <div className={styles.rootDiv}></div>
      <div className={styles.card}>
        <div>
          <p className={styles.text}>
            Dear {sessionStorage.getItem("displayName") || " Participant"},
          </p>
        </div>
        <div id="dynamic-content" className={styles.text}>
          {HtmlParser(getStorage("study_synopsis"))}
        </div>

        <div className={styles.footer}>
          <input
            className={styles.input}
            type="checkbox"
            name="termsAndConditions"
            value={checked}
            onClick={handleChange}
          />
          <p className={styles.agreeTypography}> I agree</p>
          {error && <p className={styles.error}>Please agree to continue.</p>}
        </div>

        <div className={styles.buttonParent}>
          <Button
            onClick={()=>{handleSubmit()}}
            variant="contained"
            style={Styles.Button}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Terms;

const Styles = {
  Button: {
    textTransform: "none",
    letterSpacing: 1,
    fontSize: "16px",
    width: "250px",
    fontFamily: "Avenir",
    backgroundColor: "#294199",
    padding: "16px 64px",
    margin: "10px 0 0 30px",
    color: "#f8f8f8",
    fontWeight: "700",
  },
};
