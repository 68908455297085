import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Model, StylesManager } from "survey-core";
import "survey-core/defaultV2.css";
import { Survey } from "survey-react-ui";
import { callBackAPI, saveSurveyAPI, submitSurveyAPI } from "../api/survey";
import { registerDatePicker } from "../components/customSurvey/datePickerClass";
import { registerDatePickerdob } from "../components/customSurvey/datePickerClassDob";
import Modal from "../components/modal";
import LoadingModal from "../components/modal/loadingModal";
import "../styles/surveyCustom.css";
import "../styles/surveyElement.css";
import { getLocaleDate, formatDateDob, vaccineDateFormat } from "../utils/dateFormat";
import { useTracking } from "@velocity/tracking";
import {
  checkPageState,
  getStorage,
  setPageState,
  setStorage,
} from "../utils/storage";
import {
  surveyCompleted,
  surveyError,
  surveyQuestionChange,
  surveyStart,
  surveyValueChange,
} from "../tracking/survey";
import { formatWeight } from "../utils/formatWeight";

const SurveyForm = () => {
  const [modal, setModal] = useState(false);
  StylesManager.applyTheme("defaultV2");
  const navigation = useNavigate();

  // registers custom widget for date-picker in survey
  registerDatePicker();
  registerDatePickerdob();

  const location = useLocation();
  const survey = new Model(location.state?.surveyContent);

  const saveState = (survey) => {
    let res = { currentPageNo: survey.currentPageNo, data: survey.data };
    //Here should be the code to save the data into session storage to be used for next page
    setStorage("saveState", JSON.stringify(res));
  };

  // istanbul ignore next
  survey.data = formatWeight(location.state?.resultJSON?.patient_data_json);

  saveState(survey);
  survey.setVariable(
    "dobVisibility",
    location.state?.resultJSON?.demographics_visibility_flag_json?.date_of_birth
  );
  survey.setVariable(
    "genderVisibility",
    location.state?.resultJSON?.demographics_visibility_flag_json?.gender
  );
  survey.setVariable(
    "raceVisibility",
    location.state?.resultJSON?.demographics_visibility_flag_json?.race
  );
  survey.setVariable(
    "ethnicityVisibility",
    location.state?.resultJSON?.demographics_visibility_flag_json?.ethnicity
  );

  survey.setVariable(
    "emailVisibility",
    location.state?.resultJSON?.contacts_visibility_flag_json?.email
  );
  survey.setVariable(
    "mobileNumberVisibility",
    location.state?.resultJSON?.contacts_visibility_flag_json?.mobile_number
  );

  const { getDispatcher } = useTracking({
    user_id: getStorage("userKeyValue"),
    custom_properties: {
      survey_id: getStorage("surveyId"),
      patient_id: getStorage("userKeyValue"),
    },
  });
  const dispatch = getDispatcher();

  useEffect(() => {
    const survey = checkPageState("survey");

    if (survey) {
      navigation("/thankyou");
    } else {
      surveyStart(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // registers custom widget for date-picker in survey
  registerDatePicker();

  const questions = survey.getAllQuestions();

  questions.forEach((question) => {
    question.id = question.name;
  });

  let currentPageTitle;

  let previewNavItem = survey.navigationBar.actions.find(
    (x) => x.id === "sv-nav-prev"
  );

  previewNavItem.visible = true;

  /* istanbul ignore next */
  if (survey.currentPageNo === 0) {
    previewNavItem.visible = true;
    previewNavItem.enabled = false;
  }
  survey.onCurrentPageChanged.add(() => {
    surveyQuestionChange(dispatch, survey);
    previewNavItem.enabled = true;
    /* istanbul ignore next */
    if (survey.currentPageNo === 0) {
      previewNavItem.visible = true;
      previewNavItem.enabled = false;
    }
  });
  //the below line is responsible to restrict the survey other comments to length 80
  survey.maxOthersLength = 80;
  const loadState = (survey) => {
    //Here should be the code to load the data from your database
    let storageSt = getStorage("saveState") || "";
    let res = {};
    if (storageSt) res = JSON.parse(storageSt);
    //Set the loaded data into the survey.
    if (res.currentPageNo !== undefined)
      survey.currentPageNo = res.currentPageNo;
    if (res.data) survey.data = res.data;
  };
  survey.onValueChanged.add(function (survey, options) {
    saveState(survey);
    surveyValueChange(dispatch, survey);
  });
  survey.onCurrentPageChanged.add(function (survey, options) {
    saveState(survey);
  });
  /* istanbul ignore next */
  survey.onDynamicPanelItemValueChanged.add(function (survey, options) {
    saveState(survey);
  });
  /* istanbul ignore next */
  survey.onMatrixCellValueChanged.add(function (survey, options) {
    saveState(survey);
  });

  survey.completeText = "Submit";

  if (location.state?.surveyContent) {
    let myAction = survey.addNavigationItem({
      id: "call_back_button",
      class: "call_back_button",
      title: "Request Callback ",
      enabled: !getStorage("isCallbackRequested"),
      //visibleIndex: 50, // "Complete" button has the visibleIndex 50.
      action: async () => {
        survey.clearIncorrectValues();
        currentPageTitle = survey.currentPage.questions[0].title;

        let surveyData = formatDateDob({ ...survey.data });
        if (survey.hasPageErrors) {
          surveyError(dispatch, survey);
        }
        try {
          await callBackAPI({
            data: {
              callback_object: {
                callback_status: "REQUESTED",
                callback_details: currentPageTitle,
              },
              survey: surveyData,
            },
            patient_id: getStorage("userKeyValue"),
            survey_id: getStorage("surveyId"),
          });

          alert(
            "Your request for callback has been registered. We will get back to you during our standard business hours 8AM to 6PM. "
          );
          setStorage("isCallbackRequested", true);
          myAction.enabled = !getStorage("isCallbackRequested");
        } catch (error) {
          /* istanbul ignore next */
          console.log("error", error);
        }
      },
    });

    survey.addNavigationItem({
      id: "save_button",
      title: "Save",
      // visibleIndex: 50, // "Complete" button has the visibleIndex 50.
      action: async () => {
        survey.clearIncorrectValues();

        let surveyData = formatDateDob({ ...survey.data });
        let isError = survey.hasPageErrors();
        try {
          if (!isError) {
            await saveSurveyAPI({
              survey_id: getStorage("surveyId"),
              patient_id: getStorage("userKeyValue"),
              data: surveyData,
            });

            alert("Information saved successfully.");
          } else {
            surveyError(dispatch, survey);
          }
        } catch (error) {
          /* istanbul ignore next */
          console.log("error", error);
        }
      },
    });
  }

  const alertResults = useCallback(async (sender) => {
    setModal(true);
    const surveyData = formatDateDob(sender.data);

    await updatePatientData(surveyData);
    surveyCompleted(dispatch);
    saveState(survey);
    if (
      getStorage("prescreeningStatus") === "pass" ||
      getStorage("prescreeningStatus") === "fail"
    ) {
      setPageState("survey");
      navigation("/thankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  survey.showCompletedPage = false;
  survey.onComplete.add(alertResults);

  const updatePatientData = async (json) => {
    try {
      survey.clearIncorrectValues();

      const res = await submitSurveyAPI({
        survey_id: getStorage("surveyId"),
        patient_id: getStorage("userKeyValue"),
        data: { ...json },
      });

      setStorage("studyId", res?.study_id);
      setStorage("siteId", res?.site_id);
      setStorage("ctmsPatientId", res?.ctms_patient_id);
      setStorage("prescreeningStatus", res.prescreeningStatus);
      setStorage("schedulingStatus", res.self_scheduling);
      setStorage("enrollmentSuccess", res.enrollment_success);

      setStorage(
        "vaccineDate",
        survey.data.vaccine_date
          ? vaccineDateFormat(getLocaleDate(survey.data.vaccine_date))
          : undefined
      );
    } catch (e) {
      /* istanbul ignore next */
      console.log("Error", e);
    }
  };

  loadState(survey);
  return (
    <>
      <Modal isOpen={modal}>
        <LoadingModal />
      </Modal>
      <Survey model={survey} />
    </>
  );
};

export default SurveyForm;
