import React, { useEffect } from "react";
import { SchedulingURL } from "../config/config";
import { checkPageState, getStorage } from "../utils/storage";
import styles from "../styles/thankyou.module.css";
import { useNavigate } from "react-router-dom";
import { useTracking } from "@velocity/tracking";

const Thankyou = () => {
  const navigation = useNavigate();
  const userKeyValue = getStorage("userKeyValue"),
    surveyId = getStorage("surveyId"),
    siteId = getStorage("siteId"),
    studyId = getStorage("studyId"),
    ctmsPatientId = getStorage("ctmsPatientId"),
    prescreeningStatus = getStorage("prescreeningStatus") === "pass",
    schedulingStatus =
      getStorage("schedulingStatus") === "true" ||
      getStorage("schedulingStatus") === "null",
    enrollmentSuccess = getStorage("enrollmentSuccess") === "true",
    vaccineDate = getStorage("vaccineDate");
  const isVaccineDateDefined = !(
    vaccineDate === "undefined" ||
    vaccineDate === undefined ||
    vaccineDate === null
  );

  useTracking({
    user_id: userKeyValue,
    custom_properties: {
      survey_id: surveyId,
      patient_id: userKeyValue,
      prescreening: prescreeningStatus,
      enrollmentSuccess: enrollmentSuccess,
    },
  });

  useEffect(() => {
    const state = checkPageState("thankyou");
    if (state) {
      navigation("/blank");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let url = `${SchedulingURL}/?survey_id=${surveyId}&patient_id=${userKeyValue}&study_id=${studyId}&site_id=${siteId}&ctms_patient_id=${ctmsPatientId}`;
  url = url + (isVaccineDateDefined ? `&vaccine_date=${vaccineDate}` : "");

  const showPrescreenStatusMessage = (
    prescreeningStatus,
    schedulingStatus,
    enrollmentSuccess
  ) => {
    if (prescreeningStatus) {
      if (schedulingStatus && enrollmentSuccess) {
        return (
          <div data-testid="schedule-message">
            <p className={styles.heading}> Congratulations!</p>
            <p className={styles.typography}>
              Based on the answers provided you might be a good fit to
              participate in the study. The next step is to schedule your site
              visit. Staff will be available to answer any questions you have at
              that visit. Your participation is completely voluntary.
            </p>
          </div>
        );
      } else {
        return (
          <div data-testid="no-schedule-message">
            <p className={styles.heading}> Congratulations!</p>
            <p className={styles.typography}>
              Based on the answers provided you might be a good fit to
              participate in the study. You will receive a call from one of our
              site recruiters to schedule your site visit.
            </p>
          </div>
        );
      }
    } else {
      return (
        <div data-testid="prescreen-fail-message">
          <p className={styles.typography}>
            <span className={styles.bold}>Thank you for your time.</span>{" "}
            <br></br> <br></br>
            Based on the answers you provided it looks like you are not
            currently eligible for this study.<br></br>
            <br></br>
            If you would like to learn more about other clinical trials that may
            be a better fit, <br></br>
            <br></br>
            Please contact us at &nbsp;{" "}
            <a href="https://velocitypatients.com/contact/">
              https://velocitypatients.com/contact/
            </a>{" "}
            <br></br>
            <br></br>
            We will continue to reach out when we think we may have a study that
            may be a good fit. <br></br>
            <br></br>
          </p>
        </div>
      );
    }
  };

  return (
    <div data-testid="thankyou">
      <div className={styles.card}>
        <div className={styles.innerCard}>
          {showPrescreenStatusMessage(
            prescreeningStatus,
            schedulingStatus,
            enrollmentSuccess
          )}
        </div>
        {prescreeningStatus && schedulingStatus && enrollmentSuccess ? (
          <div className={styles.center}>
            <a href={url}>
              <p className={styles.link}>Schedule&nbsp;Appointment</p>
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Thankyou;
