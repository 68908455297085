import { domainName } from "../config/config";

export const getSurveyAPI = async (survey_id, patient_id) => {
  const result = await fetch(
    `${domainName}surveys/${survey_id}/patients/${patient_id}`
  );
  let getSurveyAPIResponse = await result.json();
  return getSurveyAPIResponse;
};

export const getSurveyContent = async ({ surveyId, patientId }) => {
  try{
  const result = await fetch(
    `${domainName}surveys/${surveyId}/patients/${patientId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
    if (result.status !== 200){
      throw new Error(`Request failed with status code ${result.status}`);
      }
    console.log("The API fetch is successul",result.status);
    return result.json();

  }catch(error){
    throw new Error(`Request failed with error:" ${error}`);
  }
};

export const saveSurveyAPI = async ({ survey_id, patient_id, data }) => {
  const result = await fetch(
    `${domainName}surveys/${survey_id}/patients/${patient_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  let saveSurveyAPIResponse = await result.json();
  return saveSurveyAPIResponse;
};

export const callBackAPI = async ({ survey_id, patient_id, data }) => {
  const result = await fetch(
    `${domainName}surveys/${survey_id}/patients/${patient_id}/callback`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  let callBackAPIResponse = await result.json();
  return callBackAPIResponse;
};

export const submitSurveyAPI = async ({ survey_id, patient_id, data }) => {
  const result = await fetch(
    `${domainName}surveys/${survey_id}/patients/${patient_id}/prescreening`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
  let submitSurveyAPIResponse = await result.json();
  return submitSurveyAPIResponse;
};
