export const domainName = process.env.REACT_APP_DOMAIN_NAME;

export const SchedulingURL = process.env.REACT_APP_SCHEDULING_URL;

export const VCSurveyClientMinAge =
  process.env.REACT_APP_VC_SURVEY_CLIENT_MIN_AGE;

export const clickstreamURL = process.env.REACT_APP_CLICKSTREAM_URL;

export const firehoseName = process.env.REACT_APP_FIREHOSE_NAME;
